.landing-page-header {
    font-family: 'Book Antiqua', Arial, Helvetica, sans-serif;
    font-weight: bolder;
    font-style: italic;
    margin-bottom: 15px;
}

.landing-page-quote {
    font-family: 'Book Antiqua', Arial, Helvetica, sans-serif;
    font-style: italic;
    margin-top: 10px;
}